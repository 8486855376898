<template>
  <div>
    <!-- 列表Tab -->
    <div v-if="isList">
      <!-- 检索 -->
      <el-form :inline="true" :model="form" ref="ruleForm">
        <el-form-item class="form-item" label="用户账号" prop="keyword">
          <el-input
            v-model="form.keyword"
            placeholder="请输入员工账号"
            @keyup.enter="onSubmit"
          ></el-input>
        </el-form-item>
        <el-form-item class="form-item" label="部门" prop="department_id">
          <el-select v-model="form.department_id" placeholder="全部" clearable>
            <el-option
              v-for="(item, index) in partList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="form-item" label="小组" prop="group_id">
          <el-select v-model="form.group_id" placeholder="全部" clearable>
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="form-item" label="状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择" clearable>
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="form-item form-submit">
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 检索 -->

      <div class="table-content">
        <div class="table-title">
          <div>员工列表</div>
          <el-button
            v-if="type.indexOf('添加') > -1"
            type="primary"
            @click="handAddUser"
            >添 加</el-button
          >
        </div>
        <!-- 列表 -->
        <el-table
          :data="tableData"
          border
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="loading"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
        >
          <el-table-column
            fixed="left"
            prop="username"
            label="姓名"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="id" label="ID" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="账号" width="180" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机" width="150" align="center">
          </el-table-column>
          <el-table-column
            prop="department_name"
            label="部门"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="group_name"
            label="小组"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column label="状态" width="120" align="center">
            <template #default="scope">
              <div
                @click="handSwitch(scope.row.id, scope.row.status)"
                v-if="type.indexOf('启用/禁用') > -1"
              >
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </div>
              <div v-else>{{ scope.row.status == 1 ? "启用" : "禁用" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="last_login_time"
            label="最后一次登录时间"
            width="350"
            align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            min-width="200"
            show-overflow-tooltip
            align="center"
          >
            <template #default="scope">
              <el-button
                v-if="type.indexOf('权限') > -1"
                @click="handlePermission(scope.row.id)"
                type="text"
                size="small"
                >权限</el-button
              >
              <el-button
                v-if="type.indexOf('编辑') > -1"
                @click="handleClick(scope.row.id)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                v-if="type.indexOf('详情') > -1"
                @click="handGetInfo(scope.row.id)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button
                v-if="type.indexOf('删除') > -1 && scope.row.status == 0"
                type="text"
                size="small"
                @click="handDelete(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 列表 -->

        <!-- 分页 -->
        <Pagination
          :pageSize="pageSize"
          :currentPage="currentPage"
          :total="total"
          @handSizeChange="handSizeChange"
          @handCurrentChange="handCurrentChange"
        ></Pagination>
        <!-- 分页 -->
      </div>

      <!-- 权限抽屉 -->
      <el-drawer
        :size="300"
        title="用户权限"
        v-model="drawer"
        direction="rtl"
        destroy-on-close
        custom-class="drawer-content"
      >
        <div class="permission-drawer">
          <el-tree
            :data="data"
            show-checkbox
            node-key="id"
            default-expand-all
            :default-checked-keys="checkedArr"
            :props="defaultProps"
            @check="handChecked"
          >
          </el-tree>
          <el-button
            class="permission-btn"
            type="primary"
            @click="handDrawerSubmit"
            >确 定</el-button
          >
        </div>
      </el-drawer>
      <!-- 权限抽屉 -->
    </div>
    <!-- 列表Tab -->

    <!-- 添加/编辑Tab -->
    <div v-else>
      <el-page-header
        icon="el-icon-arrow-left"
        :content="content"
        @back="goBack"
      ></el-page-header>
      <div class="table-content">
        <!-- 添加表单 -->
        <el-form
          v-if="isAdd === 1"
          style="width: 500px"
          :model="addForm"
          :rules="rules"
          ref="addForm"
          label-width="100px"
        >
          <el-form-item label="姓名：" prop="username">
            <el-input
              v-model="addForm.username"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="账号：" prop="name">
            <el-input
              v-model="addForm.name"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input
              type="password"
              v-model="addForm.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="password_confirmation">
            <el-input
              type="password"
              v-model="addForm.password_confirmation"
              placeholder="请输入确认密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="addForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="部门：" prop="department_id">
            <el-select v-model="addForm.department_id" placeholder="请选择部门">
              <el-option
                v-for="(item, index) in partList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="小组：" prop="group_name">
            <el-select v-model="addForm.group_name" multiple>
              <el-option
                v-for="(item, index) in groupList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-radio-group v-model="addForm.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitAddForm('addForm')"
              >添加</el-button
            >
            <el-button @click="resetAddForm('addForm')">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- 添加表单 -->

        <!-- 编辑表单 -->
        <el-form
          v-else-if="isAdd === 2"
          style="width: 500px"
          :model="updateForm"
          :rules="updateRules"
          ref="updateForm"
          label-width="100px"
        >
          <el-form-item label="姓名："> {{ userInfo.username }} </el-form-item>
          <el-form-item label="账号："> {{ userInfo.name }} </el-form-item>
          <el-form-item label="密码：">
            <div v-if="isReset">
              <span>******</span>
              <el-button
                type="primary"
                style="margin-left: 20px"
                @click="isReset = false"
                >重置密码</el-button
              >
            </div>
            <div v-else>
              <el-input style="width: 300px" v-model="password"></el-input>
              <el-button
                type="primary"
                style="margin-left: 20px"
                @click="handResetSubmit"
                >确 定</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="updateForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="部门：" prop="department_id">
            <el-select
              v-model="updateForm.department_id"
              placeholder="请选择部门"
            >
              <el-option
                v-for="(item, index) in partList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="小组：" prop="group_name">
            <el-select v-model="updateForm.group_name" multiple>
              <el-option
                v-for="(item, index) in groupList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-radio-group v-model="updateForm.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('updateForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
        <!-- 编辑表单 -->

        <!-- 查看详情 -->
        <el-form v-else style="width: 500px" label-width="100px">
          <el-form-item label="姓名：">
            <div>{{ userInfo.username }}</div>
          </el-form-item>
          <el-form-item label="账号：">
            <div>{{ userInfo.name }}</div>
          </el-form-item>
          <el-form-item label="密码：">
            <div>******</div>
          </el-form-item>
          <el-form-item label="手机号码：">
            <div>{{ userInfo.phone }}</div>
          </el-form-item>
          <el-form-item label="所属部门：">
            <div>{{ userInfo.department_name }}</div>
          </el-form-item>
          <el-form-item label="所属小组：">
            <div>{{ userInfo.group_name }}</div>
          </el-form-item>
          <el-form-item label="账号状态：">
            <div>{{ userInfo.status == 1 ? "启用" : "禁用" }}</div>
          </el-form-item>
          <el-form-item label="创建时间：">
            <div>{{ userInfo.created_at }}</div>
          </el-form-item>
        </el-form>
        <!-- 查看详情 -->
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import {
  userList,
  userAdd,
  userUpdate,
  userDelete,
  userInfo,
  userResetPwd,
  userEnableDisable,
  userPermission,
  addUserPermission,
  departmentList,
  groupList,
} from "@/api/apiList/system-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        page: 1,
        pageSize: 10,
        keyword: "",
        department_id: "",
        group_id: "",
        status: "",
      },
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      total: 10,
      loading: true,
      drawer: false,
      data: [],
      defaultProps: {
        children: "children",
        label: "menu_name",
      },
      partList: [],
      groupList: [],
      userId: "",
      type: "",
      content: "添加员工",
      isList: true,
      isAdd: 1,
      userInfo: {},
      addForm: {
        username: "",
        name: "",
        password: "",
        password_confirmation: "",
        phone: "",
        department_id: "",
        group_name: [],
        group_id: "",
        status: "",
      },
      rules: {
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        name: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        password_confirmation: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
        ],
        department_id: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        status: [
          { required: true, message: "请选择是否启用", trigger: "change" },
        ],
      },
      updateForm: {
        id: "",
        phone: "",
        department_id: "",
        group_name: [],
        group_id: "",
        status: "",
      },
      updateRules: {
        department_id: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        status: [
          { required: true, message: "请选择是否启用", trigger: "change" },
        ],
      },
      isReset: true,
      password: "******",
      checkedArr: [],
      checkedAll: [],
    };
  },
  methods: {
    // 检索
    onSubmit() {
      this.loading = true;
      this.handList();
    },
    // 检索重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 添加
    handAddUser() {
      this.addForm = {
        username: "",
        name: "",
        password: "",
        password_confirmation: "",
        phone: "",
        department_id: "",
        group_name: [],
        group_id: "",
        status: "",
      };
      this.content = "添加员工";
      this.isAdd = 1;
      this.isList = false;
    },
    // 返回列表
    goBack() {
      this.isList = true;
    },
    // 添加提交
    submitAddForm(formName) {
      let phone =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.addForm.password_confirmation !== this.addForm.password) {
        this.$message.error("两次密码输入不一致");
      } else if (!phone.test(this.addForm.phone) && this.addForm.phone !== "") {
        this.$message.error("请填写正确的手机号");
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.addForm.group_id = this.addForm.group_name.toString();
            userAdd(this.addForm)
              .then((res) => {
                if (res.result === "200") {
                  this.isList = true;
                  this.$message.success({
                    message: res.data.info,
                    type: "success",
                  });
                  this.handList();
                }
              })
              .catch((err) => {
                this.$message.error(err.data.error.errors.phone[0]);
              });
          } else {
            return false;
          }
        });
      }
    },
    // 添加重置
    resetAddForm(formName) {
      this.addForm.group_id = "";
      this.$refs[formName].resetFields();
    },
    // 编辑
    handleClick(e) {
      let obj = {
        id: e,
      };
      userInfo(obj).then((res) => {
        if (res.result === "200") {
          this.updateForm.id = e;
          this.updateForm.phone = res.data.phone;
          this.updateForm.department_id = res.data.department_id;
          if (res.data.group_id) {
            if (res.data.group_id.toString().indexOf(",") > -1) {
              this.updateForm.group_name = res.data.group_id
                .split(",")
                .map((item) => parseInt(item));
            } else {
              let arr = new Array(1);
              arr[0] = parseInt(res.data.group_id);
              this.updateForm.group_name = arr;
            }
          } else {
            this.updateForm.group_name = [];
          }
          this.updateForm.group_id = res.data.group_id;
          this.updateForm.status = res.data.status;
          this.userInfo = res.data;
          this.userId = e;
          this.content = "编辑员工";
          this.isAdd = 2;
          this.isList = false;
        }
      });
    },
    // 编辑提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateForm.group_id = this.updateForm.group_name.toString();
          userUpdate(this.updateForm).then((res) => {
            if (res.result === "200") {
              this.isList = true;
              this.$message.success({
                message: res.data.info,
                type: "success",
              });
              this.handList();
            } else if (res.result === 300024) {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 查看员工
    handGetInfo(e) {
      let obj = {
        id: e,
      };
      userInfo(obj).then((res) => {
        if (res.result === "200") {
          this.userInfo = res.data;
          this.content = "查看员工";
          this.isAdd = 3;
          this.isList = false;
        }
      });
    },
    // 删除员工
    handDelete(e) {
      this.$confirm("确定要删除此员工?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            id: e,
          };
          userDelete(obj).then((res) => {
            if (res.result === "200") {
              this.$message.success({
                message: res.data.info,
                type: "success",
              });
              this.handList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 启用/禁用
    handSwitch(id, e) {
      if (id !== undefined) {
        let str = e == 1 ? "启用" : "禁用";
        this.$confirm("确定要" + str + "此员工?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let obj = {
              id: id,
              status: e,
            };
            userEnableDisable(obj).then((res) => {
              if (res.result === "200") {
                this.$message({
                  type: "success",
                  message: res.data.info,
                });
                this.handList();
              }
            });
          })
          .catch(() => {
            this.handList();
          });
      }
    },
    // 权限选择
    handChecked(e, id) {
      this.checkedAll = id.checkedKeys.concat(id.halfCheckedKeys);
    },
    // 权限提交
    handDrawerSubmit() {
      let arr = this.checkedAll.map((item) => ({ permission_id: item }));
      arr.forEach((item) => {
        item["model_id"] = this.userId;
      });
      let obj = {
        user_rights_arr: JSON.stringify(arr),
      };
      addUserPermission(obj).then((res) => {
        if (res.result === "200") {
          this.drawer = false;
          this.$message.success({
            message: res.data.info,
            type: "success",
          });
          this.handList();
        }
      });
    },
    // 员工权限
    handlePermission(e) {
      let obj = {
        userid: e,
      };
      userPermission(obj).then((res) => {
        if (res.result === "200") {
          this.handCheckArr(res.data);
          this.data = res.data;
          this.drawer = true;
          this.userId = e;
        }
      });
    },
    // 重置密码
    handResetSubmit() {
      let obj = {
        id: this.userId,
        newpwd: this.password,
      };
      userResetPwd(obj).then((res) => {
        if (res.result === "200") {
          this.isReset = true;
          this.$message.success({
            message: res.data.info,
            type: "success",
          });
        }
      });
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handList();
    },
    // 权限方法封装
    handCheckArr(Arr) {
      function flatten(arr) {
        return [].concat(
          ...arr.map((item) => [].concat(item, ...flatten(item.children)))
        );
      }
      this.checkedAll = flatten(Arr)
        .filter((item) => item.is_check == 1)
        .map((item) => item.id);
      this.checkedArr = flatten(Arr)
        .filter((item) => item.is_check == 1 && item.leixing == 2)
        .map((item) => item.id);
    },
    handList() {
      userList(this.form).then((res) => {
        if (res.result === "200") {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    departmentList().then((res) => {
      if (res.result === "200") {
        this.partList = res.data.data.filter((item) => item.status == 2);
      }
    });
    groupList().then((res) => {
      if (res.result === "200") {
        this.groupList = res.data.data.filter((item) => item.status == 2);
      }
    });
    this.handList();
  },
};
</script>

<style lang="scss">
.drawer-content {
  overflow: auto;

  .permission-drawer {
    padding: 0 20px;
    padding-bottom: 40px;
    text-align: center;

    .permission-btn {
      margin-top: 30px;
    }
  }
}
</style>
